/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Staking from "./pages/Staking";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Home from "./pages/Home";
import { WagmiProvider } from "wagmi";
import { providers } from "ethers";

const alchemyApiKey = process.env.REACT_APP_ALCHEMY_API_KEY;
const shouldHideStaking =
  (process.env.REACT_APP_SHOULD_HIDE_STAKING || "").toLocaleLowerCase() ===
  "true";

function App() {
  const provider = ({ chainId }) =>
    new providers.AlchemyProvider(chainId, alchemyApiKey);

  return (
    <WagmiProvider autoConnect provider={provider}>
      <Router>
        <div id="wrapper" css={css``}>
          <Header shouldHideStaking={shouldHideStaking}></Header>
          <div
            css={css`
              min-height: calc(100vh - 158.29px);
            `}
          >
            <Routes>
              <Route exact path="/" element={<Home />} />
              {!shouldHideStaking && (
                <Route exact path="/staking" element={<Staking />} />
              )}
              <Route exact path="*" element={<Home />} />
            </Routes>
          </div>
          <Footer></Footer>
        </div>
      </Router>
    </WagmiProvider>
  );
}

export default App;
