/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { intervalToDuration, parseISO } from "date-fns";
import { Col, Container, Row, Spinner, Stack } from "react-bootstrap";
import * as cronjsMatcher from "@datasert/cronjs-matcher";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { db } from "../utils/firestore";
import { doc } from "firebase/firestore";

const StakingDashboardHeader = ({ address }) => {
  // Load wallet earnings
  const [data, loading, error] = useDocumentData(
    doc(db, "wallets", address.toLowerCase())
  );

  if (loading || error) {
    return (
      <div
        css={css`
          justify-content: center;
          display: flex;
          padding: 40px;
        `}
      >
        {loading ? (
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        ) : (
          "Error, please try again..."
        )}
      </div>
    );
  }

  return (
    <Container
      fluid
      css={css`
        border-bottom: 2px solid rgba(255, 255, 255, 0.2);
        box-sizing: border-box;
        background: #ffffff1a;
      `}
    >
      <Row>
        <Col lg={true}>
          <Row>
            <SynthesisGroup>
              <SynthesisTitle>Account</SynthesisTitle>
              <SynthesisText>{address.substring(0, 6) + "..."}</SynthesisText>
            </SynthesisGroup>

            <SynthesisGroup
              css={css`
                @media (max-width: 992px) {
                  align-items: flex-end;
                }
              `}
            >
              <SynthesisTitle>Update In</SynthesisTitle>
              <SynthesisText>{data ? updateIn() : "-"}</SynthesisText>
            </SynthesisGroup>
          </Row>
        </Col>
        <Col lg={true}>
          <Row>
            <SynthesisGroup>
              <SynthesisTitle>Lock Up Period</SynthesisTitle>
              <SynthesisText>
                {data ? "Until Oct. 31th 2022" : "-"}
              </SynthesisText>
            </SynthesisGroup>

            <SynthesisGroup
              css={css`
                align-items: flex-end;
              `}
            >
              <SynthesisTitle
                css={css`
                  text-align: right;
                  color: #8c4fff;
                `}
              >
                Total Earned
              </SynthesisTitle>
              <SynthesisText>{data ? data.earnings : 0} BOO</SynthesisText>
            </SynthesisGroup>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default StakingDashboardHeader;

const updateIn = () => {
  const cron = "0 0 * * *";
  const futureMatches = cronjsMatcher.getFutureMatches(cron, { matchCount: 1 });
  const duration = intervalToDuration({
    start: parseISO(new Date().toISOString()),
    end: parseISO(futureMatches[0]),
  });
  const updateIn =
    (duration.hours !== 0
      ? `${duration.hours} hr${duration.hours > 1 ? "s " : " "}`
      : "") +
    (duration.minutes !== 0
      ? `${duration.minutes} mn${duration.minutes > 1 ? "s" : ""}`
      : "");
  return updateIn;
};

const SynthesisTitle = (props) => {
  return (
    <div
      css={css`
        font-family: "Inter";
        font-style: normal;
        font-weight: 700;
        font-size: 11px;
        line-height: 89%;
        /* identical to box height, or 10px */

        letter-spacing: 0.1em;
        text-transform: uppercase;

        color: #ffffff;
        padding-bottom: 10px;
      `}
      {...props}
    >
      {props.children}
    </div>
  );
};

const SynthesisText = (props) => {
  return (
    <div
      css={css`
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 150%;
        color: #ffffff;

        color: #ffffff;
      `}
    >
      {props.children}
    </div>
  );
};

const SynthesisGroup = (props) => {
  return (
    <Col>
      <Stack
        gap={0}
        css={css`
          padding: 30px;

          @media (max-width: 576px) {
            padding: 15px;
          }

          display: flex;
          align-items: flex-start;
        `}
      >
        {props.children}
      </Stack>
    </Col>
  );
};
