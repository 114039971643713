/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { rarityToEarningMapping, canUnstake } from "../utils/config";
import { doc } from "firebase/firestore";
import { db } from "../utils/firestore";
import { format } from "date-fns";

const BabyghostCard = ({ handleModalOpen, data, address, tokenId }) => {
  // Load token earnings and stake date
  const [dataToken] = useDocumentData(
    doc(db, "wallets", address.toLowerCase(), "tokens", tokenId)
  );
  const earnings = dataToken && dataToken.earnings ? dataToken.earnings : 0;
  const stakedAt =
    dataToken && dataToken.stakedAt
      ? format(dataToken.stakedAt.toDate(), "MMMM do y")
      : "-";

  return (
    <Card
      css={css`
        background-color: #020303;
        border: 1px solid rgba(255, 255, 255, 0.2);
        box-sizing: border-box;
        border-radius: 5px;
        margin-right: 15px;
        margin-bottom: 15px;
        max-width: 300px;
      `}
    >
      <Card.Img
        variant="top"
        src={data.image}
        css={css`
          padding: 15px;
          border-radius: 20px;
        `}
      />
      <TextContainer>
        <Rarity rarity={data.rarity} />
        <Name name={data.name} />
      </TextContainer>

      <TextContainer>
        <EarningsContainer
          data={data}
          handleModalOpen={handleModalOpen}
          earnings={earnings}
        />
      </TextContainer>

      {data.isStaked && (
        <TextContainer>
          <StakedSince stakedAt={stakedAt} />
        </TextContainer>
      )}
    </Card>
  );
};

export default BabyghostCard;

const TextContainer = (props) => {
  return (
    <div
      css={css`
        padding-top: 20px;
        padding-bottom: 20px;
        padding-left: 15px;
        padding-right: 15px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.2);
      `}
    >
      {props.children}
    </div>
  );
};

const Rarity = ({ rarity }) => {
  return (
    <div
      css={css`
        font-family: "Inter";
        font-style: normal;
        font-weight: 700;
        font-size: 11px;
        line-height: 89%;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: #ffffff;
        opacity: 0.5;
        padding-bottom: 5px;
      `}
    >
      {rarity}
    </div>
  );
};
const Name = ({ name }) => {
  return (
    <div
      css={css`
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 150%;
        color: #ffffff;
      `}
    >
      {name}
    </div>
  );
};

const EarningsContainer = ({ data, handleModalOpen, earnings }) => {
  return (
    <Container
      css={css`
        padding: 0px;
      `}
    >
      <Row
        sm={"auto"}
        css={css`
          font-family: "Inter";
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 150%;
          justify-content: space-between;
        `}
      >
        {data.isStaked ? (
          <EarningsStaked rarity={data.rarity} earnings={earnings} />
        ) : (
          <EarningsToStake rarity={data.rarity} />
        )}
        <Col
          css={css`
            display: flex;
            justify-content: end;
            flex-direction: column;
          `}
        >
          {!data.isStaked || canUnstake ? (
            <button
              onClick={handleModalOpen}
              css={css`
                color: #9156ff;
                background: transparent;
                border-radius: 20px;
                border: 0px solid;
              `}
            >
              {data.isStaked ? "Unstake" : "Stake"}
            </button>
          ) : (
            ""
          )}
        </Col>
      </Row>
    </Container>
  );
};

const EarningsToStake = ({ rarity }) => {
  return (
    <Row xs={"auto"}>
      <div>
        <span
          css={css`
            color: #ffffff;
          `}
        >
          {rarityToEarningMapping[rarity].toString()} BOO
        </span>

        <span
          css={css`
            color: rgba(255, 255, 255, 0.4);
          `}
        >
          /day
        </span>
        <img
          alt="Boo token"
          src="/images/coin.png"
          css={css`
            height: 22px;
            width: auto;
            padding-left: 10px;
            top: 2px;
            position: relative;
          `}
        />
      </div>
    </Row>
  );
};

const EarningsStaked = ({ rarity, earnings }) => {
  return (
    <Col css={css``}>
      <Row
        xs={"auto"}
        className="g-0"
        css={css`
          font-family: "Inter";
          font-style: normal;
          font-weight: 700;
          font-size: 11px;
          line-height: 89%;
          letter-spacing: 0.1em;
          text-transform: uppercase;
          color: #8c4fff;
          padding-bottom: 5px;
        `}
      >
        TOTAL EARNED
      </Row>
      <Row xs={"auto"} className="g-0">
        <span
          css={css`
            font-family: "Inter";
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 150%;
            color: #ffffff;
          `}
        >
          {earnings} BOO
        </span>
        <img
          alt="Boo token"
          src="/images/coin.png"
          css={css`
            height: 22px;
            width: auto;
            padding-left: 10px;
            top: 2px;
            position: relative;
          `}
        />
      </Row>
      <Row
        xs={"auto"}
        className="g-0"
        css={css`
          font-family: "Inter";
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 150%;
          color: rgba(255, 255, 255, 0.5);
        `}
      >
        {rarityToEarningMapping[rarity].toString()} BOO/day
      </Row>
    </Col>
  );
};

const StakedSince = ({ stakedAt }) => {
  return (
    <div>
      <div
        css={css`
          font-family: "Inter";
          font-style: normal;
          font-weight: 700;
          font-size: 11px;
          line-height: 89%;
          letter-spacing: 0.1em;
          text-transform: uppercase;
          color: #ffffff;
          opacity: 0.5;
          padding-bottom: 5px;
        `}
      >
        STAKED SINCE
      </div>
      <div
        css={css`
          font-family: "Inter";
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 150%;

          color: #ffffff;
        `}
      >
        {stakedAt}
      </div>
    </div>
  );
};
