/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Modal, Spinner } from "react-bootstrap";
import useStake from "../utils/useStake";
import BabyghostLine from "./BabyghostLine";

const StakeModal = ({
  data,
  showModal,
  handleModalClose,
  tokenIds,
  address,
  revert,
}) => {
  // Close the modal after 3 sec
  const closeAfter3sec = async () => {
    return new Promise(() => setTimeout(handleModalClose, 3000));
  };

  const onSuccess = closeAfter3sec;
  const onError = closeAfter3sec;

  let [{ success, loading, error }, stake, initState] = useStake({
    tokenIds,
    address,
    onSuccess,
    onError,
    revert,
  });

  const canCloseModal = !loading || error;

  return (
    <Modal
      show={showModal}
      // Prevent closing the modal while loading unless there was an error
      onHide={(e) => (canCloseModal ? handleModalClose() : "")}
      onExited={initState}
      centered
      dialogClassName="dialog-class"
      css={css`
        .dialog-class {
          /*   @media (max-width: 768px) {
            width: 90%;
            height: 90%;
            top: 5%;
            left: 5%;
          } */
        }

        .modal-content {
          background: #111111;
          border: 2px solid rgba(255, 255, 255, 0.2);
          box-sizing: border-box;
          border-radius: 10px;
          padding: 20px;
        }
      `}
    >
      <Modal.Header
        closeButton={canCloseModal}
        closeVariant="white"
        css={css`
          border-bottom: none;
          padding: 0px;
          height: 16px;
        `}
      ></Modal.Header>
      <ModalTitle />
      <ModalSubTitle />
      <br />
      <ModalBodyContainer>
        <div
          css={
            tokenIds.length > 2
              ? css`
                  padding-right: 15px;
                `
              : ""
          }
        >
          {tokenIds.map((tokenId, index) => (
            <BabyghostLine
              key={tokenId}
              data={data[tokenId]}
              css={
                index > 0
                  ? css`
                      position: relative;
                      top: -1px;
                    `
                  : ""
              }
            />
          ))}
        </div>
      </ModalBodyContainer>
      <br />
      <ModalWarning />
      <br />
      <ModalFooter
        loading={loading}
        error={error}
        success={success}
        confirmStaking={stake}
        revert={revert}
      />
    </Modal>
  );
};

export default StakeModal;

const ModalTitle = () => {
  return (
    <div
      css={css`
        font-family: demonregular;
        font-style: normal;
        font-weight: 400;
        line-height: 100%;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        text-align: center;
        font-size: 50px;
        @media (max-width: 768px) {
          font-size: 30px;
        }
      `}
    >
      Confirm soft-stake
    </div>
  );
};

const ModalSubTitle = () => {
  return (
    <div
      css={css`
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 150%;
        color: #ffffff;
        text-align: center;
        @media (max-width: 768px) {
          font-size: 16px;
        }
      `}
    >
      Locked until{" "}
      <span
        css={css`
          font-weight: 600;
          color: #784dc8;
        `}
      >
        Oct, 31th 2022
      </span>
    </div>
  );
};

const ModalWarning = () => {
  return (
    <div
      css={css`
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 10px;
        line-height: 160%;

        color: #ffffff;

        padding: 15px 30px;
        background: rgba(255, 255, 255, 0.07);
        border-radius: 5px;
      `}
    >
      If you transfer or sell your NFTs during the lock period, the stake will
      become disqualified and you will no longer be eligible for rewards.
    </div>
  );
};

const ModalBodyContainer = (props) => {
  return (
    <div
      css={css`
        text-align: center;
      `}
    >
      <div
        css={css`
          max-height: 190px;
          overflow: auto;
          ::-webkit-scrollbar {
            width: 5px;
          }
          ::-webkit-scrollbar {
            background: black;
          }
          ::-webkit-scrollbar-thumb {
            background: white;
            border-radius: 20px;
          }
        `}
      >
        {props.children}
      </div>
    </div>
  );
};

const ModalFooter = ({ loading, error, success, confirmStaking, revert }) => {
  if (error) {
    return (
      <Modal.Footer
        css={css`
          justify-content: center;
          border-top: none;
          padding: 0;
        `}
      >
        <ModalFooterError error={error} />
      </Modal.Footer>
    );
  }
  if (success) {
    return (
      <Modal.Footer
        css={css`
          justify-content: center;
          border-top: none;
          padding: 0;
        `}
      >
        <ModalFooterSuccess />
      </Modal.Footer>
    );
  }

  return (
    <Modal.Footer
      css={css`
        justify-content: center;
        border-top: none;
        padding: 0;
      `}
    >
      <ModalFooterButton
        loading={loading}
        confirmStaking={confirmStaking}
        revert={revert}
      />
    </Modal.Footer>
  );
};

const ModalFooterButton = ({
  loading,
  error,
  success,
  confirmStaking,
  revert,
}) => {
  return (
    <button
      type="button"
      className="btn-outline"
      css={css`
        background: #784dc8;
        border-radius: 100px;
        border-color: #784dc8;
        height: 40px;
        margin: 8px;
        margin-bottom: 7px;
      `}
      onClick={confirmStaking}
      disabled={loading}
    >
      {loading ? (
        <>
          <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
          />
          <span className="visually-hidden">Loading...</span>
        </>
      ) : (
        <span
          css={css`
            font-family: "Inter";
            font-style: normal;
            font-weight: 700;
            font-size: 11px;
            line-height: 109%;
            letter-spacing: 0.1em;
            text-transform: uppercase;
          `}
        >
          {!revert ? "Stake" : "Unstake"}
        </span>
      )}
    </button>
  );
};

const ModalFooterError = ({ error }) => {
  return (
    <div
      css={css`
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 10px;
        line-height: 160%;
        color: #ffffff;

        padding: 10px 30px;
        background: #dd172d80;
        border-radius: 5px;
        width: 100%;
      `}
    >
      {error}
    </div>
  );
};
const ModalFooterSuccess = () => {
  return (
    <div
      css={css`
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 10px;
        line-height: 160%;
        color: #ffffff;
        padding: 10px 30px;
        background: #5b8d31;
        border-radius: 5px;
        color: #ffffff;
        width: 100%;
      `}
    >
      Successfully locked
    </div>
  );
};
