import { useLocation } from "react-router-dom";

function Footer() {
  const location = useLocation();
  const getHref = (page) =>
    (location.pathname === "/staking" ? "/" : "") + page;
  return (
    <footer id="footer">
      <div className="footer-block">
        <div className="container">
          <div className="footer-logo">
            <a href={getHref("#section1")}>
              <img src="images/logo.png" alt="Baby Ghosts NFT. collection" />
            </a>
          </div>
          <ul className="footer-btns">
            <li>
              <a
                href="https://discord.gg/PWJaJ9c8Fq"
                target="_blank"
                rel="noreferrer"
                className="btn-outline"
              >
                <span className="btn-inner">
                  20k MEMBERS <span className="icon-discord1 icon"></span>
                </span>
              </a>
            </li>
            <li>
              <a
                href="https://twitter.com/BabyGhosts_NFT"
                target="_blank"
                rel="noreferrer"
                className="btn-outline btn-twitter"
              >
                <span className="btn-inner">
                  13k FOLLOWERS <span className="icon-twitter1 icon"></span>
                </span>
              </a>
            </li>
          </ul>
          <ul className="footer-nav">
            <li>
              <a
                href="https://opensea.io/collection/babyghosts"
                target="_blank"
                rel="noreferrer"
              >
                <img src="images/opensea.svg" alt="Opensea" />
              </a>
            </li>
            <li>
              <a
                href="https://discord.gg/PWJaJ9c8Fq"
                target="_blank"
                rel="noreferrer"
              >
                <img src="images/discord.svg" alt="discord" />
              </a>
            </li>
            <li>
              <a
                href="https://twitter.com/BabyGhosts_NFT"
                target="_blank"
                rel="noreferrer"
              >
                <img src="images/twitter.svg" alt="Twitter" />
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className="footer-area">
        <div className="container">
          <ul className="copyright">
            <li>Copyright &copy; Baby Ghosts 2021</li>
            <li> All Rights Reserved</li>
            <li>
              <a
                href="terms.html"
                target="popup"
                onClick={() =>
                  window.open("terms.html", "popup", "width=600,height=600")
                }
              >
                Terms of Use
              </a>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
