/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import BabyghostCard from "./BabyghostCard";
import { Container, Row, Spinner, Tab, Tabs } from "react-bootstrap";
import useNftsData from "../utils/useNftsData";
import StakeModal from "./StakeModal";
import { useState } from "react";

const StakingDashboardTabs = ({ tokenIds, address }) => {
  // For Modal
  const [showModal, setShowModal] = useState(false);
  const [revert, setRevert] = useState(false);
  const [selectedTokenIds, setSelectedTokenIds] = useState([]);
  const handleModalClose = () => setSelectedTokenIds([]) & setShowModal(false);
  const handleModalOpenFactory = (tokenIds, revert) => () =>
    setSelectedTokenIds(tokenIds) & setRevert(revert) & setShowModal(true);

  // Load nft data
  const {
    data: dataNft,
    loading: loadingNft,
    error: errorNft,
  } = useNftsData({ tokenIds, address });

  const stakedIds = dataNft
    ? tokenIds.filter((tokenId) => dataNft[tokenId].isStaked)
    : [];
  const notStakedIds = dataNft
    ? tokenIds.filter((tokenId) => !dataNft[tokenId].isStaked)
    : [];

  const error = errorNft;
  const loading = loadingNft || !dataNft;

  if (loading || error) {
    return (
      <div
        css={css`
          justify-content: center;
          display: flex;
          padding: 40px;
        `}
      >
        {loading ? (
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        ) : (
          "Error, please try again..."
        )}
      </div>
    );
  }

  return (
    <>
      <TabsContainer>
        <Tab eventKey="toStake" title="Stake your baby ghost">
          <TabContentContainer>
            <TabContentTitle>
              You have {notStakedIds.length} Baby Ghosts
            </TabContentTitle>
            <TabContentStakeAllButton
              handleModalOpen={handleModalOpenFactory(notStakedIds, false)}
              isStaked={false}
            />
            <TabContentListContainer>
              {notStakedIds.map((tokenId) => (
                <BabyghostCard
                  key={tokenId}
                  tokenId={tokenId}
                  data={dataNft[tokenId]}
                  address={address}
                  handleModalOpen={handleModalOpenFactory([tokenId], false)}
                />
              ))}
            </TabContentListContainer>
          </TabContentContainer>
        </Tab>
        <Tab eventKey="staked" title="Your staking list">
          <TabContentContainer>
            <TabContentTitle>
              You have {stakedIds.length} Baby Ghosts staked
            </TabContentTitle>
            {stakedIds.length > 0 && (
              <TabContentStakeAllButton
                handleModalOpen={handleModalOpenFactory(stakedIds, true)}
                isStaked={true}
              />
            )}
            <TabContentListContainer>
              {stakedIds.map((tokenId) => (
                <BabyghostCard
                  key={tokenId}
                  address={address}
                  tokenId={tokenId}
                  data={dataNft[tokenId]}
                  handleModalOpen={handleModalOpenFactory([tokenId], true)}
                />
              ))}
            </TabContentListContainer>
          </TabContentContainer>
        </Tab>
      </TabsContainer>
      <StakeModal
        data={dataNft}
        showModal={showModal}
        handleModalClose={handleModalClose}
        tokenIds={selectedTokenIds}
        address={address}
        revert={revert}
      />
    </>
  );
};

export default StakingDashboardTabs;

const TabsContainer = (props) => {
  return (
    <Tabs
      defaultActiveKey="toStake"
      css={css`
        box-sizing: border-box;
        border-bottom: 2px solid rgba(255, 255, 255, 0.2);

        .nav-link {
          padding: 30px;
          color: #ffffff;
          opacity: 0.3;
          margin-right: 20px;
          margin-left: 20px;
          font-family: "Inter";
          font-style: normal;
          font-weight: 700;
          font-size: 11px;
          line-height: 89%;
          letter-spacing: 0.1em;
          text-transform: uppercase;
          border: none;
        }

        .nav-link.active {
          color: #ffffff;
          opacity: 1;
          background-color: transparent;
          border: none;
          border-bottom: 1px solid #ffffff;
        }
      `}
    >
      {props.children}
    </Tabs>
  );
};

const TabContentContainer = (props) => {
  return (
    <div
      css={css`
        padding: 30px;
        text-align: left;
      `}
    >
      {props.children}
    </div>
  );
};

const TabContentTitle = (props) => {
  return (
    <div
      css={css`
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 150%;
        color: #ffffff;
      `}
    >
      {props.children}
    </div>
  );
};

const TabContentStakeAllButton = ({ handleModalOpen, isStaked }) => {
  return (
    <button
      onClick={handleModalOpen}
      css={css`
        font-family: "Inter";
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 150%;
        color: #9156ff;
        padding-left: 0px;
        background: transparent;
        border-radius: 20px;
        border: 0px solid;
      `}
    >
      {isStaked ? "Unstake all" : "Stake all"}
    </button>
  );
};

const TabContentListContainer = (props) => {
  return (
    <div
      css={css`
        display: flex;
      `}
    >
      <Container
        css={css`
          padding: 0px;
          padding-top: 15px;
        `}
      >
        <Row className="g-0">{props.children}</Row>
      </Container>
    </div>
  );
};
