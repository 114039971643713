// Mapping for earning
export const rarityToEarningMapping = {
  Chilling: 10, // 6600 items
  Freaky: 33, // 2000 items
  Ethereal: 51, // 1300 items
  Hallowed: 660, // 100 items
};

// Let user unstake item
export const canUnstake = true;
