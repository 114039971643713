/* eslint-disable jsx-a11y/anchor-is-valid */
import Soldout from "../components/Soldout";

function Home() {
  return (
    <main id="main">
      <section className="banner viewport-section" id="section1">
        <div className="container">
          <div className="bg-stretch bg-holloween">
            <span data-srcset="images/bg11.png, images/bg11@2x.png 2x"></span>
            <span
              data-srcset="images/bg11.png, images/bg11@2x.png 2x"
              data-media="(min-width: 768px)"
            ></span>
            <span
              data-srcset="images/bg11.png, images/bg11@2x.png 2x"
              data-media="(min-width: 1024px)"
            ></span>
          </div>
          <div className="text-bg-holder">
            <span className="title title-red">WELCOME TO</span>
            <h1>Baby Ghosts</h1>
          </div>
          <div className="ghosts">
            <div className="ghost1">
              <picture>
                <source
                  srcSet="images/banner-ghost1.png, images/banner-ghost1@2x.png 2x"
                  media="(min-width: 768px)"
                />
                <source
                  srcSet="images/banner-ghost1.png, images/banner-ghost1@2x.png 2x"
                  media="(min-width: 1024px)"
                />
                <source srcSet="images/banner-ghost1.png, images/banner-ghost1@2x.png 2x" />
                <img src="images/banner-ghost1.png" alt="Ghost" />
              </picture>
            </div>
            <div className="ghost2">
              <picture>
                <source
                  srcSet="images/banner-ghost2.png, images/banner-ghost2@2x.png 2x"
                  media="(min-width: 768px)"
                />
                <source
                  srcSet="images/banner-ghost2.png, images/banner-ghost2@2x.png 2x"
                  media="(min-width: 1024px)"
                />
                <source srcSet="images/banner-ghost2.png, images/banner-ghost2@2x.png 2x" />
                <img src="images/banner-ghost2.png" alt="Ghost" />
              </picture>
            </div>
            <div className="ghost3">
              <picture>
                <source
                  srcSet="images/banner-ghost3.png, images/banner-ghost3@2x.png 2x"
                  media="(min-width: 768px)"
                />
                <source
                  srcSet="images/banner-ghost3.png, images/banner-ghost3@2x.png 2x"
                  media="(min-width: 1024px)"
                />
                <source srcSet="images/banner-ghost3.png, images/banner-ghost3@2x.png 2x" />
                <img src="images/banner-ghost3.png" alt="Ghost" />
              </picture>
            </div>
          </div>
          <Soldout />
        </div>
      </section>
      <section className="info-block">
        <div className="container">
          <div className="heading">
            <span className="title title-light-red">
              No sorcery may exorcise them!
            </span>
            <h1>BABY GHOSTS</h1>
            <div className="heading-wrap">
              <p>
                When a wraith daddy and a banshee mommy love each other very
                much, something magical happens. Out of the cabbage patch next
                to the graveyard rises a brand new generation of revenants! At
                pre-K, Baby Ghosts make friends and discover the world, until
                one day, the whole daycare is exorcised and all Baby Ghosts are
                released!
              </p>
              <p>
                Now lost spirits and spread to the wind, Baby Ghosts are free to
                haunt throughout the Earth.
              </p>
            </div>
          </div>
          <div className="sliders">
            <div className="custom-slide">
              <div className="slide">
                <picture>
                  <source
                    srcSet="images/slide1.jpg, images/slide1@2x.jpg 2x"
                    media="(min-width: 768px)"
                  />
                  <source
                    srcSet="images/slide1.jpg, images/slide1@2x.jpg 2x"
                    media="(min-width: 1024px)"
                  />
                  <source srcSet="images/slide1.jpg, images/slide1@2x.jpg 2x" />
                  <img src="images/slide1.jpg" alt="Ghost" />
                </picture>
              </div>
              <div className="slide">
                <picture>
                  <source
                    srcSet="images/slide2.jpg, images/slide2@2x.jpg 2x"
                    media="(min-width: 768px)"
                  />
                  <source
                    srcSet="images/slide2.jpg, images/slide2@2x.jpg 2x"
                    media="(min-width: 1024px)"
                  />
                  <source srcSet="images/slide2.jpg, images/slide2@2x.jpg 2x" />
                  <img src="images/slide2.jpg" alt="Ghost" />
                </picture>
              </div>
              <div className="slide">
                <picture>
                  <source
                    srcSet="images/slide3.jpg, images/slide3@2x.jpg 2x"
                    media="(min-width: 768px)"
                  />
                  <source
                    srcSet="images/slide3.jpg, images/slide3@2x.jpg 2x"
                    media="(min-width: 1024px)"
                  />
                  <source srcSet="images/slide3.jpg, images/slide3@2x.jpg 2x" />
                  <img src="images/slide3.jpg" alt="Ghost" />
                </picture>
              </div>
              <div className="slide">
                <picture>
                  <source
                    srcSet="images/slide4.jpg, images/slide4@2x.jpg 2x"
                    media="(min-width: 768px)"
                  />
                  <source
                    srcSet="images/slide4.jpg, images/slide4@2x.jpg 2x"
                    media="(min-width: 1024px)"
                  />
                  <source srcSet="images/slide4.jpg, images/slide4@2x.jpg 2x" />
                  <img src="images/slide4.jpg" alt="Ghost" />
                </picture>
              </div>
              <div className="slide">
                <picture>
                  <source
                    srcSet="images/slide5.jpg, images/slide5@2x.jpg 2x"
                    media="(min-width: 768px)"
                  />
                  <source
                    srcSet="images/slide5.jpg, images/slide5@2x.jpg 2x"
                    media="(min-width: 1024px)"
                  />
                  <source srcSet="images/slide5.jpg, images/slide5@2x.jpg 2x" />
                  <img src="images/slide5.jpg" alt="Ghost" />
                </picture>
              </div>
              <div className="slide">
                <picture>
                  <source
                    srcSet="images/slide6.jpg, images/slide6@2x.jpg 2x"
                    media="(min-width: 768px)"
                  />
                  <source
                    srcSet="images/slide6.jpg, images/slide6@2x.jpg 2x"
                    media="(min-width: 1024px)"
                  />
                  <source srcSet="images/slide6.jpg, images/slide6@2x.jpg 2x" />
                  <img src="images/slide6.jpg" alt="Ghost" />
                </picture>
              </div>
            </div>
            <div className="custom-slide custom-slide--right">
              <div className="slide">
                <picture>
                  <source
                    srcSet="images/slide4.jpg, images/slide4@2x.jpg 2x"
                    media="(min-width: 768px)"
                  />
                  <source
                    srcSet="images/slide4.jpg, images/slide4@2x.jpg 2x"
                    media="(min-width: 1024px)"
                  />
                  <source srcSet="images/slide4.jpg, images/slide4@2x.jpg 2x" />
                  <img src="images/slide4.jpg" alt="Ghost" />
                </picture>
              </div>
              <div className="slide">
                <picture>
                  <source
                    srcSet="images/slide6.jpg, images/slide6@2x.jpg 2x"
                    media="(min-width: 768px)"
                  />
                  <source
                    srcSet="images/slide6.jpg, images/slide6@2x.jpg 2x"
                    media="(min-width: 1024px)"
                  />
                  <source srcSet="images/slide6.jpg, images/slide6@2x.jpg 2x" />
                  <img src="images/slide6.jpg" alt="Ghost" />
                </picture>
              </div>
              <div className="slide">
                <picture>
                  <source
                    srcSet="images/slide7.jpg, images/slide7@2x.jpg 2x"
                    media="(min-width: 768px)"
                  />
                  <source
                    srcSet="images/slide7.jpg, images/slide7@2x.jpg 2x"
                    media="(min-width: 1024px)"
                  />
                  <source srcSet="images/slide7.jpg, images/slide7@2x.jpg 2x" />
                  <img src="images/slide7.jpg" alt="Ghost" />
                </picture>
              </div>
              <div className="slide">
                <picture>
                  <source
                    srcSet="images/slide8.jpg, images/slide8@2x.jpg 2x"
                    media="(min-width: 768px)"
                  />
                  <source
                    srcSet="images/slide8.jpg, images/slide8@2x.jpg 2x"
                    media="(min-width: 1024px)"
                  />
                  <source srcSet="images/slide8.jpg, images/slide8@2x.jpg 2x" />
                  <img src="images/slide8.jpg" alt="Ghost" />
                </picture>
              </div>
              <div className="slide">
                <picture>
                  <source
                    srcSet="images/slide9.jpg, images/slide9@2x.jpg 2x"
                    media="(min-width: 768px)"
                  />
                  <source
                    srcSet="images/slide9.jpg, images/slide9@2x.jpg 2x"
                    media="(min-width: 1024px)"
                  />
                  <source srcSet="images/slide9.jpg, images/slide9@2x.jpg 2x" />
                  <img src="images/slide9.jpg" alt="Ghost" />
                </picture>
              </div>
              <div className="slide">
                <picture>
                  <source
                    srcSet="images/slide10.jpg, images/slide10@2x.jpg 2x"
                    media="(min-width: 768px)"
                  />
                  <source
                    srcSet="images/slide10.jpg, images/slide10@2x.jpg 2x"
                    media="(min-width: 1024px)"
                  />
                  <source srcSet="images/slide10.jpg, images/slide10@2x.jpg 2x" />
                  <img src="images/slide10.jpg" alt="Ghost" />
                </picture>
              </div>
              <div className="slide">
                <picture>
                  <source
                    srcSet="images/slide11.jpg, images/slide11@2x.jpg 2x"
                    media="(min-width: 768px)"
                  />
                  <source
                    srcSet="images/slide11.jpg, images/slide11@2x.jpg 2x"
                    media="(min-width: 1024px)"
                  />
                  <source srcSet="images/slide11.jpg, images/slide11@2x.jpg 2x" />
                  <img src="images/slide11.jpg" alt="Ghost" />
                </picture>
              </div>
            </div>
            <div className="custom-slide">
              <div className="slide">
                <picture>
                  <source
                    srcSet="images/slide6.jpg, images/slide6@2x.jpg 2x"
                    media="(min-width: 768px)"
                  />
                  <source
                    srcSet="images/slide6.jpg, images/slide6@2x.jpg 2x"
                    media="(min-width: 1024px)"
                  />
                  <source srcSet="images/slide6.jpg, images/slide6@2x.jpg 2x" />
                  <img src="images/slide6.jpg" alt="Ghost" />
                </picture>
              </div>
              <div className="slide">
                <picture>
                  <source
                    srcSet="images/slide7.jpg, images/slide7@2x.jpg 2x"
                    media="(min-width: 768px)"
                  />
                  <source
                    srcSet="images/slide7.jpg, images/slide7@2x.jpg 2x"
                    media="(min-width: 1024px)"
                  />
                  <source srcSet="images/slide7.jpg, images/slide7@2x.jpg 2x" />
                  <img src="images/slide7.jpg" alt="Ghost" />
                </picture>
              </div>
              <div className="slide">
                <picture>
                  <source
                    srcSet="images/slide8.jpg, images/slide8@2x.jpg 2x"
                    media="(min-width: 768px)"
                  />
                  <source
                    srcSet="images/slide8.jpg, images/slide8@2x.jpg 2x"
                    media="(min-width: 1024px)"
                  />
                  <source srcSet="images/slide8.jpg, images/slide8@2x.jpg 2x" />
                  <img src="images/slide8.jpg" alt="Ghost" />
                </picture>
              </div>
              <div className="slide">
                <picture>
                  <source
                    srcSet="images/slide9.jpg, images/slide9@2x.jpg 2x"
                    media="(min-width: 768px)"
                  />
                  <source
                    srcSet="images/slide9.jpg, images/slide9@2x.jpg 2x"
                    media="(min-width: 1024px)"
                  />
                  <source srcSet="images/slide9.jpg, images/slide9@2x.jpg 2x" />
                  <img src="images/slide9.jpg" alt="Ghost" />
                </picture>
              </div>
              <div className="slide">
                <picture>
                  <source
                    srcSet="images/slide10.jpg, images/slide10@2x.jpg 2x"
                    media="(min-width: 768px)"
                  />
                  <source
                    srcSet="images/slide10.jpg, images/slide10@2x.jpg 2x"
                    media="(min-width: 1024px)"
                  />
                  <source srcSet="images/slide10.jpg, images/slide10@2x.jpg 2x" />
                  <img src="images/slide10.jpg" alt="Ghost" />
                </picture>
              </div>
              <div className="slide">
                <picture>
                  <source
                    srcSet="images/slide11.jpg, images/slide11@2x.jpg 2x"
                    media="(min-width: 768px)"
                  />
                  <source
                    srcSet="images/slide11.jpg, images/slide11@2x.jpg 2x"
                    media="(min-width: 1024px)"
                  />
                  <source srcSet="images/slide11.jpg, images/slide11@2x.jpg 2x" />
                  <img src="images/slide11.jpg" alt="Ghost" />
                </picture>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="goals" id="section2">
        <div className="bg-stretch bg">
          <span data-srcset="images/bg13.png, images/bg13@2x.png 2x"></span>
          <span
            data-srcset="images/bg13.png, images/bg13@2x.png 2x"
            data-media="(min-width: 768px)"
          ></span>
          <span
            data-srcset="images/bg13.png, images/bg13@2x.png 2x"
            data-media="(min-width: 1024px)"
          ></span>
        </div>
        <div className="container">
          <div className="heading">
            <span className="title title-green">Mint them all!</span>
            <h1>THE GOALS</h1>
            <div className="heading-wrap">
              <p>
                Baby Ghosts are crossing over! Now's your chance to grab yours
                before they reach the sky. Bred in a loving haunted household
                and eternally newborn, 10 000 Baby Ghosts are looking for a
                forever home in your wallet.
              </p>
            </div>
          </div>
          <ul className="goal-list">
            <li>
              <span className="percentage">
                <span className="texture">25</span>
                <sup>%</sup>
              </span>
              <p>
                We will giveaway <strong>10 Baby Ghosts</strong> to our Discord
                members and <strong>1 ETH</strong> to a random OG Baby Ghost
                owner from the pre-sale whitelist.
              </p>
            </li>
            <li>
              <span className="percentage">
                <span className="texture">50</span>
                <sup>%</sup>
              </span>
              <p>
                We will be donating <strong>$30,000</strong> to St. Jude
                Children's Research Hospital (Memphis, USA).
              </p>
            </li>
            <li>
              <span className="percentage">
                <span className="texture">75</span>
                <sup>%</sup>
              </span>
              <p>
                We will be launching our Baby Ghosts merch online store.{" "}
                <strong>100%</strong> of the sales will go directly to the
                community wallet.
              </p>
            </li>
            <li>
              <span className="percentage">
                <span className="texture">100</span>
                <sup>%</sup>
              </span>
              <p>
                We will be launching a new{" "}
                <strong>Baby Ghosts theme NFTs collection</strong> with
                exclusive pre-sale access for Baby Ghosts owners. In addition to
                that, <strong>10 ETH</strong> will be distributed among 10 Baby
                Ghosts owners.
              </p>
            </li>
          </ul>
        </div>
      </section>
      <section className="two-column viewport-section" id="section3">
        <div className="container">
          <div className="two-col">
            <div className="col">
              <div className="bg-stretch bg">
                <span data-srcset="images/img1-sm.png, images/img1-sm@2x.png 2x"></span>
                <span
                  data-srcset="images/img1-sm.png, images/img1-sm@2x.png 2x"
                  data-media="(min-width: 768px)"
                ></span>
                <span
                  data-srcset="images/img1.png, images/img1@2x.png 2x"
                  data-media="(min-width: 1024px)"
                ></span>
              </div>
              <div className="img">
                <div className="bg-stretch bg">
                  <span data-srcset="images/circle1.png, images/circle1@2x.png 2x"></span>
                  <span
                    data-srcset="images/circle1.png, images/circle1@2x.png 2x"
                    data-media="(min-width: 768px)"
                  ></span>
                  <span
                    data-srcset="images/circle1.png, images/circle1@2x.png 2x"
                    data-media="(min-width: 1024px)"
                  ></span>
                </div>
                <div className="ghost fade-up">
                  <picture>
                    <source
                      srcSet="images/img-ghost.png, images/img-ghost@2x.png 2x"
                      media="(min-width: 768px)"
                    />
                    <source
                      srcSet="images/img-ghost.png, images/img-ghost@2x.png 2x"
                      media="(min-width: 1024px)"
                    />
                    <source srcSet="images/img-ghost.png, images/img-ghost@2x.png 2x" />
                    <img src="images/img-ghost.png" alt="Ghost" />
                  </picture>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="heading">
                <span className="title">An apparition from preschool!</span>
                <h1>RARITY</h1>
                <p>
                  Find your kindred spirit among 10 000 of possible Baby Ghost
                  combinations! Each Baby Ghost is unique.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="slider-block viewport-section">
        <div className="container">
          <div className="slider">
            <div className="slide">
              <div className="slide-wrap">
                <div className="img">
                  <div className="bg-stretch bg">
                    <span data-srcset="images/bg1.png, images/bg1@2x.png 2x"></span>
                    <span
                      data-srcset="images/bg1.png, images/bg1@2x.png 2x"
                      data-media="(min-width: 768px)"
                    ></span>
                    <span
                      data-srcset="images/bg1.png, images/bg1@2x.png 2x"
                      data-media="(min-width: 1024px)"
                    ></span>
                  </div>
                  <div className="ghost">
                    <picture>
                      <source
                        srcSet="images/img2.png, images/img2@2x.png 2x"
                        media="(min-width: 768px)"
                      />
                      <source
                        srcSet="images/img2.png, images/img2@2x.png 2x"
                        media="(min-width: 1024px)"
                      />
                      <source srcSet="images/img2.png, images/img2@2x.png 2x" />
                      <img src="images/img2.png" alt="Ghost" />
                    </picture>
                  </div>
                </div>
                <div className="text">
                  <span className="sub-title">66% chances drop</span>
                  <h2>Chilling</h2>
                  <p>
                    The most common tier of Baby Ghost, but there just aren't
                    enough of them to go around! They won't pale in comparison.
                  </p>
                </div>
              </div>
            </div>
            <div className="slide">
              <div className="slide-wrap">
                <div className="img">
                  <div className="bg-stretch bg">
                    <span data-srcset="images/bg2.png, images/bg2@2x.png 2x"></span>
                    <span
                      data-srcset="images/bg2.png, images/bg2@2x.png 2x"
                      data-media="(min-width: 768px)"
                    ></span>
                    <span
                      data-srcset="images/bg2.png, images/bg2@2x.png 2x"
                      data-media="(min-width: 1024px)"
                    ></span>
                  </div>
                  <div className="ghost">
                    <picture>
                      <source
                        srcSet="images/img3.png, images/img3@2x.png 2x"
                        media="(min-width: 768px)"
                      />
                      <source
                        srcSet="images/img3.png, images/img3@2x.png 2x"
                        media="(min-width: 1024px)"
                      />
                      <source srcSet="images/img3.png, images/img3@2x.png 2x" />
                      <img src="images/img3.png" alt="Ghost" />
                    </picture>
                  </div>
                </div>
                <div className="text">
                  <span className="sub-title blue">20% chances drop</span>
                  <h2>Freaky</h2>
                  <p>
                    A step-up in value from their chilling counterpart, freaky
                    Baby Ghosts are ready to make a fuss.
                  </p>
                </div>
              </div>
            </div>
            <div className="slide">
              <div className="slide-wrap">
                <div className="img">
                  <div className="bg-stretch bg">
                    <span data-srcset="images/bg3.png, images/bg3@2x.png 2x"></span>
                    <span
                      data-srcset="images/bg3.png, images/bg3@2x.png 2x"
                      data-media="(min-width: 768px)"
                    ></span>
                    <span
                      data-srcset="images/bg3.png, images/bg3@2x.png 2x"
                      data-media="(min-width: 1024px)"
                    ></span>
                  </div>
                  <div className="ghost">
                    <picture>
                      <source
                        srcSet="images/img4.png, images/img4@2x.png 2x"
                        media="(min-width: 768px)"
                      />
                      <source
                        srcSet="images/img4.png, images/img4@2x.png 2x"
                        media="(min-width: 1024px)"
                      />
                      <source srcSet="images/img4.png, images/img4@2x.png 2x" />
                      <img src="images/img4.png" alt="Ghost" />
                    </picture>
                  </div>
                </div>
                <div className="text">
                  <span className="sub-title green">13% chances drop</span>
                  <h2>Ethereal</h2>
                  <p>
                    Starting at this tier, Baby ghosts go beyond with premium
                    traits combinations. These Baby Ghosts are to die for.
                  </p>
                </div>
              </div>
            </div>
            <div className="slide">
              <div className="slide-wrap">
                <div className="img">
                  <div className="bg-stretch bg">
                    <span data-srcset="images/bg05.png, images/bg05@2x.png 2x"></span>
                    <span
                      data-srcset="images/bg05.png, images/bg05@2x.png 2x"
                      data-media="(min-width: 768px)"
                    ></span>
                    <span
                      data-srcset="images/bg05.png, images/bg05@2x.png 2x"
                      data-media="(min-width: 1024px)"
                    ></span>
                  </div>
                  <div className="ghost">
                    <picture>
                      <source
                        srcSet="images/img05.png, images/img05@2x.png 2x"
                        media="(min-width: 768px)"
                      />
                      <source
                        srcSet="images/img05.png, images/img05@2x.png 2x"
                        media="(min-width: 1024px)"
                      />
                      <source srcSet="images/img05.png, images/img05@2x.png 2x" />
                      <img src="images/img05.png" alt="Ghost" />
                    </picture>
                  </div>
                </div>
                <div className="text">
                  <span className="sub-title yellow">1% chances drop</span>
                  <h2>Hallowed</h2>
                  <p>
                    The most festive tier, these Baby Ghosts put the spirit in
                    Halloween! Celebrate the day of the dead all year long.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="faq-block">
        <div className="container">
          <div className="faq-wrap" id="section4">
            <div className="bg-stretch bg">
              <span data-srcset="images/bg8.jpg, images/bg8@2x.jpg 2x"></span>
              <span
                data-srcset="images/bg8.jpg, images/bg8@2x.jpg 2x"
                data-media="(min-width: 768px)"
              ></span>
              <span
                data-srcset="images/bg8.jpg, images/bg8@2x.jpg 2x"
                data-media="(min-width: 1024px)"
              ></span>
            </div>
            <div className="heading heading-right">
              <span className="title title-purple">Haunt me more!</span>
              <h1 className="visible-xs">F.A.Q</h1>
              <h1 className="hidden-xs">
                FREQUENTLY <br /> ASKED QUESTIONS
              </h1>
              <div className="heading-wrap">
                <p>
                  Each Baby Ghost is unique and programmatically generated from
                  over 140 possible traits, including backgrounds, skins, eyes,
                  disguises, accessories...
                </p>
              </div>
            </div>
            <ul className="accordion">
              <li>
                <a href="#" className="opener">
                  What is an NFT?<span className="plus"></span>
                </a>
                <div className="drop">
                  <div className="drop-holder">
                    <p>
                      NFT stands for “Non-fungible token” and is a cool way of
                      saying it’s a truly unique digital item that YOU can buy,
                      own, and trade.
                    </p>
                  </div>
                </div>
              </li>
              <li>
                <a href="#" className="opener">
                  What is Metamask?<span className="plus"></span>
                </a>
                <div className="drop">
                  <div className="drop-holder">
                    <p>
                      Metamask is a crypto-wallet that can store your Ethereum,
                      and is needed to purchase and mint a Baby Ghost. Having a
                      wallet gives you an Ethereum address (i.e. 0xSPOO….666),
                      this is where your NFT will be stored. Learn more about
                      Metamask and how easy it is to use over here! (
                      <a
                        href="https://metamask.io/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        https://metamask.io
                      </a>
                      ).
                    </p>
                  </div>
                </div>
              </li>
              <li>
                <a href="#" className="opener">
                  How to mint with Metamask on a mobile phone?
                  <span className="plus"></span>
                </a>
                <div className="drop">
                  <div className="drop-holder">
                    <p>
                      If you are using a mobile phone to mint our lovely Baby
                      Ghosts, you need to use the Metamask application built-in
                      browser to mint our NFTs. Therefore, please launch the
                      Metamask application, click the 3 lines on the top left
                      menu in the application and select "Browser". It will open
                      a web browser and you will be able to navigate back to
                      babyghosts.com to do the minting.
                    </p>
                  </div>
                </div>
              </li>
              <li>
                <a href="#" className="opener">
                  How to mint with Metamask on a computer?
                  <span className="plus"></span>
                </a>
                <div className="drop">
                  <div className="drop-holder">
                    <p>
                      If you are using a computer to mint our lovely Baby
                      Ghosts, you just need to connect the Metamask plugin with
                      our website, verify you have enough ETH to do the
                      transaction, then you will be able to click on the Mint
                      button to buy a few Baby Ghosts.
                    </p>
                  </div>
                </div>
              </li>
              <li>
                <a href="#" className="opener">
                  Buying NFT for the first time?<span className="plus"></span>
                </a>
                <div className="drop">
                  <div className="drop-holder">
                    <p>
                      Get the{" "}
                      <a
                        href="https://metamask.io/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Metamask
                      </a>{" "}
                      Chrome or Firefox extension. Load it with ETH through
                      services that allow you to change your money to ETH like
                      Coinbase, Binance or Kraken. Then, click the button "MINT"
                      on the scary banner to connect your wallet and approve the
                      transaction on Metamask. That’s it !
                    </p>
                  </div>
                </div>
              </li>
              <li>
                <a href="#" className="opener">
                  Where does my NFT go after I purchase a Baby Ghost?
                  <span className="plus"></span>
                </a>
                <div className="drop">
                  <div className="drop-holder">
                    <p>
                      Your Baby Ghost NFT will appear in whatever address, or
                      connected wallet you used to purchase the Baby Ghost. You
                      can see your freshly minted NFT card directly on your{" "}
                      <a
                        href="https://opensea.io/account"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Opensea.io
                      </a>{" "}
                      account.
                    </p>
                  </div>
                </div>
              </li>
              <li>
                <a href="#" className="opener">
                  That sounds great, how do I get in?
                  <span className="plus"></span>
                </a>
                <div className="drop">
                  <div className="drop-holder">
                    <p>
                      Join our{" "}
                      <a
                        href="https://discord.gg/PWJaJ9c8Fq"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Discord
                      </a>{" "}
                      (https://discord.gg/BabyGhosts) server or{" "}
                      <a
                        href="https://twitter.com/BabyGhosts_NFT"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Twitter
                      </a>{" "}
                      (https://twitter.com/BabyGhosts_NFT) account and jump in
                      on the conversation & share your ideas!
                    </p>
                  </div>
                </div>
              </li>
              <li>
                <a href="#" className="opener">
                  What can I do with my Baby Ghosts / How can I trade them?
                  <span className="plus"></span>
                </a>
                <div className="drop">
                  <div className="drop-holder">
                    <p>
                      You are free to do anything with them under a
                      non-exclusive license. Baby Ghosts adheres to the ERC-721
                      standard so you can trade them on platforms like OpenSea.
                    </p>
                  </div>
                </div>
              </li>
              <li>
                <a href="#" className="opener">
                  Are Baby Ghosts a good investment?
                  <span className="plus"></span>
                </a>
                <div className="drop">
                  <div className="drop-holder">
                    <p>
                      Well, yeah! At least we believe that Baby Ghosts have a
                      long life of haunting ahead of them, and will keep growing
                      as the project doesn’t end here! Hopefully Baby Ghosts go
                      to the moon, but like anything in life, don’t spend money
                      you can’t afford not to have.
                    </p>
                  </div>
                </div>
              </li>
              <li>
                <a href="#" className="opener">
                  What about Tokenomics?<span className="plus"></span>
                </a>
                <div className="drop">
                  <div className="drop-holder">
                    <p>
                      We have reserved 100 Baby Ghosts to giveaway. Most of
                      these will be given out after launch and will not occupy
                      the early token ids. We will also use a % of mint sales to
                      occasionally sweep the floor, do giveways, and create
                      merch!
                    </p>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </section>
    </main>
  );
}

export default Home;
