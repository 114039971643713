/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import { rarityToEarningMapping } from "../utils/config";

const BabyghostLine = ({ data, ...props }) => {
  return (
    <div
      css={css`
        flex-direction: row;
        display: flex;
        justify-content: space-between;
        border: 1px solid #2b2b2b;
        border
        box-sizing: border-box;
        padding-right: 10px;
        padding-left: 10px;
        padding-top: 10px;
        padding-bottom: 10px;
      `}
      {...props}
    >
      <div
        css={css`
          display: flex;
          text-align: left;
        `}
      >
        <div
          css={css`
            display: flex;
            justify-content: center;
            flex-direction: column;
          `}
        >
          <img
            alt="babyghost"
            src={data.image}
            css={css`
              border-radius: 5px;
              height: 50px;
              margin-right: 10px;
            `}
          />
        </div>

        <div
          css={css`
            justify-content: space-between;
            display: flex;
            flex-direction: column;
            padding: 5px;
          `}
        >
          <div
            css={css`
              display: flex;
              justify-content: start;
              flex-direction: column;
            `}
          >
            <Rarity rarity={data.rarity} />
          </div>
          <div
            css={css`
              display: flex;
              justify-content: end;
              flex-direction: column;
            `}
          >
            <Name tokenId={data.tokenId} />
          </div>
        </div>
      </div>
      <div
        css={css`
          display: flex;
          justify-content: end;
          flex-direction: column;
          padding-bottom: 5px;
        `}
      >
        <Earning rarity={data.rarity} />
      </div>
    </div>
  );
};

export default BabyghostLine;

const Rarity = ({ rarity }) => {
  return (
    <span
      css={css`
        font-family: "Inter";
        font-style: normal;
        font-weight: 700;
        font-size: 11px;
        line-height: 89%;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: #ffffff;
        opacity: 0.5;
      `}
    >
      {rarity}
    </span>
  );
};

const Name = ({ tokenId }) => {
  return (
    <span
      css={css`
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 150%;
        color: #ffffff;
      `}
    >
      <span className="d-sm-block d-md-none"> {`#${tokenId}`}</span>
      <span className="d-none d-md-block"> {`Baby Ghost #${tokenId}`}</span>
    </span>
  );
};

const Earning = ({ rarity }) => {
  return (
    <span
      css={css`
        font-family: "Inter";
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 150%;
      `}
    >
      <span
        css={css`
          color: #ffffff;
        `}
      >
        {rarityToEarningMapping[rarity].toString()} BOO
      </span>
      <span
        css={css`
          color: rgba(255, 255, 255, 0.4);
        `}
      >
        /day
      </span>
      <img
        alt="Boo token"
        src="/images/coin.png"
        css={css`
          height: 22px;
          padding-left: 10px;
          top: 2px;
          position: relative;
        `}
      />
    </span>
  );
};
