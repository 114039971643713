/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import StakingDashboardHeader from "./StakingDashboardHeader";
import StakingDashboardTabs from "./StakingDashboardTabs";
import { BigNumber } from "ethers";
import { useContractRead } from "wagmi";
import contractABI from "../utils/contract-abi.json";
import { Spinner } from "react-bootstrap";

const contractAddress = process.env.REACT_APP_CONTRACT_ADDRESS;

const StakingDashboard = ({ address }) => {
  // Load user nfts
  const [{ data, error, loading }] = useContractRead(
    {
      addressOrName: contractAddress,
      contractInterface: contractABI,
    },
    "walletOfOwner",
    { args: [address] }
  );

  if (loading || error || !data) {
    return (
      <div
        css={css`
          justify-content: center;
          display: flex;
          padding: 40px;
        `}
      >
        {loading || !data ? (
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        ) : (
          "Error, please try again..."
        )}
      </div>
    );
  }
  const tokenIds = data.map((x) => BigNumber.from(x).toString());

  return (
    <Container>
      <div
        css={css`
          border: 2px solid rgba(255, 255, 255, 0.2);
          box-sizing: border-box;
          border-radius: 10px;
          margin-bottom: 60px;
        `}
      >
        <StakingDashboardHeader address={address} />

        {tokenIds.length === 0 ? (
          <DisplayNoNfts />
        ) : (
          <StakingDashboardTabs tokenIds={tokenIds} address={address} />
        )}
      </div>
    </Container>
  );
};

export default StakingDashboard;

const DisplayNoNfts = () => {
  const openLink = (e) => {
    const newWindow = window.open(
      "https://opensea.io/collection/babyghosts?search[sortAscending]=true&search[sortBy]=PRICE&search[toggles][0]=BUY_NOW",
      "_blank",
      "noopener,noreferrer"
    );
    e.preventDefault();
    if (newWindow) newWindow.opener = null;
  };
  return (
    <div
      css={css`
        padding: 30px;
      `}
    >
      <p
        css={css`
          font-family: "Inter";
          font-style: normal;
          font-weight: 400;
          font-size: 20px;
          line-height: 150%;
          color: #ffffff;
        `}
      >
        You don't have any Baby Ghost in your wallet
      </p>
      <a
        href="/"
        alt="Buy on OpenSea"
        onClick={openLink}
        css={css`
          font-family: "Inter";
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 150%;
          color: #5579fe;
        `}
      >
        Buy on OpenSea
      </a>
    </div>
  );
};

const Container = (props) => {
  return (
    <div
      css={css`
        padding-left: 40px;
        padding-right: 40px;
      `}
    >
      {props.children}
    </div>
  );
};
