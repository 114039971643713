/** @jsxImportSource @emotion/react */
import { useConnect } from "wagmi";
import { css } from "@emotion/react";
import { isMobile } from "react-device-detect";

function ConnectButton() {
  const [{ data, loading }, connect] = useConnect();
  const connector = data.connectors[0];

  if (!connector.ready) {
    if (isMobile) {
      return (
        <ConnectButtonContainer>
          <Button
            text={"Open with MetaMask"}
            onClick={() => {
              const url = window.location.href.replace("https://", "");
              window.open(`https://metamask.app.link/dapp/${url}`);
            }}
            disabled={false}
          />
        </ConnectButtonContainer>
      );
    } else {
      return (
        <ConnectButtonContainer>
          <Button text={"Please install MetaMask first"} disabled={true} />
        </ConnectButtonContainer>
      );
    }
  }
  return (
    <ConnectButtonContainer>
      <Button
        text={loading ? "Loading..." : "Connect MetaMask"}
        disabled={loading}
        onClick={() => connect(connector)}
      />
    </ConnectButtonContainer>
  );
}

function Button({ onClick, text, disabled }) {
  return (
    <button
      type="button"
      className="btn-outline"
      onClick={onClick}
      disabled={disabled}
      css={css`
        background-color: transparent;
        background: #784dc8;
        border-radius: 100px;

        font-family: "Inter";
        font-style: normal;
        font-weight: 700;
        font-size: 11px;
        line-height: 109%;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: #ffffff;
        border: none;
        padding: 20px;
      `}
    >
      {text}
    </button>
  );
}

function ConnectButtonContainer(props) {
  return (
    <div
      css={css`
        padding-bottom: 30px;
      `}
    >
      {
        // Version large screen
      }
      <div
        className="d-none d-lg-block"
        css={css`
          margin-left: 40px;
          margin-right: 40px;
          padding: 30px;
          border: 2px solid rgba(255, 255, 255, 0.2);
          box-sizing: border-box;
          border-radius: 10px;
        `}
      >
        <div>
          <div
            css={css`
              font-family: "Inter";
              font-style: normal;
              font-weight: 700;
              font-size: 11px;
              line-height: 89%;
              letter-spacing: 0.1em;
              text-transform: uppercase;
              color: #ffffff;
              padding-bottom: 30px;
            `}
          >
            Your wallet is not connected, get started below
          </div>
          {props.children}
        </div>
      </div>

      {
        // Version below large screen
      }
      <div
        className="d-mb-block d-lg-none"
        css={css`
          text-align: center;
          border-top: 2px solid rgb(255 255 255 / 20%);
          padding-top: 30px;
          margin-top: 70px;
        `}
      >
        <div>
          <div
            css={css`
              font-family: "Inter";
              font-style: normal;
              font-weight: 700;
              font-size: 11px;
              line-height: 89%;
              letter-spacing: 0.1em;
              text-transform: uppercase;
              color: #ffffff;
              padding-bottom: 30px;
            `}
          >
            Your wallet is not connected, get started below
          </div>
          {props.children}
        </div>
      </div>
    </div>
  );
}

export default ConnectButton;
