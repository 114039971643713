import { useState } from "react";
import axios from "axios";
import { ethers } from "ethers";

const cloudFunctionUrl =
  process.env.REACT_APP_FIREBASE_CLOUD_FUNCTION_URL_OVERWRITE || "";

const useStake = ({ tokenIds, address, onSuccess, onError, revert }) => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const initState = () =>
    setLoading(false) & setSuccess(false) & setError(false);

  const stake = async () => {
    try {
      setLoading(true);

      // Get for a random nonce to sign
      const { nonce } = (
        await axios({
          // Url rewrite is automatically set in firebase.json
          url: `${cloudFunctionUrl}/getNonceToSign`,
          method: "POST",
          data: {
            address,
          },
        })
      ).data;

      // Ask the user to sign the nonce
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const signature = await signer.signMessage(nonce);

      // Verify the signed nonce and get make the staking request
      const response = await axios({
        // Url rewrite is automatically set in firebase.json
        url: `${cloudFunctionUrl}/verifySignedNonceAndStake`,
        method: "POST",
        data: {
          address,
          signature,
          tokenIds,
          revert,
        },
      });

      setLoading(false);

      // Eventually frow an error
      if (response.data.error) {
        throw new Error(response.data.error);
      }

      setSuccess(true);

      // Trigger success callback
      if (onSuccess) {
        onSuccess();
      }
    } catch (err) {
      setLoading(false);
      setError(err.message);
      onError();
    }
  };
  return [
    {
      success,
      loading,
      error,
    },
    stake,
    initState,
  ];
};

export default useStake;
