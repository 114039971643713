/* eslint-disable jsx-a11y/anchor-is-valid */
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useState } from "react";
import { Modal } from "react-bootstrap";

import { Link, useLocation } from "react-router-dom";
function Header({ shouldHideStaking }) {
  return (
    <>
      <HeaderSmall
        shouldHideStaking={shouldHideStaking}
        className="d-sm-block d-md-none"
      />
      <HeaderLarge
        shouldHideStaking={shouldHideStaking}
        className="d-none d-md-block"
      />
    </>
  );
}

function HeaderLarge({ shouldHideStaking, ...props }) {
  // This trick is used to keep smooth scrolling while being on '/'
  // and reload page when switching back from another page to '/'
  // so jquery (and slick) is loaded again
  const location = useLocation();
  const getHref = (page) =>
    (location.pathname === "/staking" ? "/" : "") + page;
  return (
    <header id="header" {...props}>
      <div className="container">
        <div className="logo">
          <a href={getHref("#section1")}>
            <img src="images/logo.png" alt="Baby Ghosts NFT Collection" />
          </a>
        </div>
        <nav id="nav">
          <ul className="menu-list">
            <li>
              <a href={getHref("#section1")}>Welcome</a>
            </li>
            <li>
              <a href={getHref("#section2")}>Goals</a>
            </li>
            <li>
              <a href={getHref("#section3")}>Rarity</a>
            </li>
            <li>
              <a href={getHref("#section4")}>FAQ</a>
            </li>
            {!shouldHideStaking && (
              <li>
                <Link to="/staking">Staking</Link>
              </li>
            )}
            
          </ul>
          <ul className="social-list">
            <li>
              <a
                href="https://opensea.io/collection/babyghosts"
                target="_blank"
                rel="noreferrer"
              >
                <img src="images/opensea.svg" alt="Opensea" />
              </a>
            </li>
            <li>
              <a
                href="https://discord.gg/PWJaJ9c8Fq"
                target="_blank"
                rel="noreferrer"
              >
                <img src="images/discord.svg" alt="discord" />
              </a>
            </li>
            <li>
              <a
                href="https://twitter.com/BabyGhosts_NFT"
                target="_blank"
                rel="noreferrer"
              >
                <img src="images/twitter.svg" alt="twitter" />
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  );
}

function HeaderSmall({ shouldHideStaking, ...props }) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <header id="header" {...props}>
      <div
        className="container"
        css={css`
          justify-content: center;
        `}
      >
        <div className="logo">
          <img src="images/logo.png" alt="Baby Ghosts NFT Collection" />
        </div>
        <div
          className="logo"
          css={css`
            position: absolute;
            right: 20px;
            cursor: pointer;
          `}
        >
          <img
            src="images/menu-icon.png"
            alt="Menu"
            css={css`
              filter: invert(93%) sepia(44%) saturate(21%) hue-rotate(342deg)
                brightness(105%) contrast(105%);
              width: 60px;
            `}
            onClick={handleShow}
          />
        </div>
      </div>
      <HeaderSmallModal
        show={show}
        handleClose={handleClose}
        shouldHideStaking={shouldHideStaking}
      />
    </header>
  );
}

function HeaderSmallModal({ show, handleClose, shouldHideStaking }) {
  return (
    <Modal
      show={show}
      onHide={handleClose}
      fullscreen={true}
      contentClassName="menu-modal"
      css={css`
        .menu-modal {
          background: #121111;
        }
      `}
    >
      <Modal.Header
        closeVariant="white"
        closeButton
        css={css`
          border: none;
        `}
      ></Modal.Header>
      <div
        className="logo"
        css={css`
          margin-right: auto;
          margin-left: auto;
        `}
      >
        <img src="images/logo.png" alt="Baby Ghosts NFT Collection" />
      </div>
      <br />
      <ModalLink text="Welcome" link="/#section1" handleClose={handleClose} />
      <ModalLink text="Goals" link="/#section2" handleClose={handleClose} />
      <ModalLink text="Rarity" link="/#section3" handleClose={handleClose} />
      <ModalLink text="FAQ" link="/#section4" handleClose={handleClose} />
      {!shouldHideStaking && (
        <ModalLink text="Staking" link="/staking" handleClose={handleClose} />
      )}
      
      <br />
      <br />

      <div
        className="social-list"
        css={css`
          padding-right: 80px;
          padding-left: 80px;
          justify-content: space-between;
          display: flex;
        `}
      >
        <a
          href="https://opensea.io/collection/babyghosts"
          target="_blank"
          rel="noreferrer"
        >
          <img
            src="images/opensea.svg"
            alt="Opensea"
            css={css`
              width: 50px;
            `}
          />
        </a>
        <a
          href="https://discord.gg/PWJaJ9c8Fq"
          target="_blank"
          rel="noreferrer"
        >
          <img
            src="images/discord.svg"
            alt="discord"
            css={css`
              width: 50px;
            `}
          />
        </a>
        <a
          href="https://twitter.com/BabyGhosts_NFT"
          target="_blank"
          rel="noreferrer"
        >
          <img
            src="images/twitter.svg"
            alt="twitter"
            css={css`
              width: 50px;
            `}
          />
        </a>
      </div>
    </Modal>
  );
}

const ModalLink = ({ text, link, handleClose }) => {
  return (
    <a
      href={link}
      onClick={handleClose}
      css={css`
        padding: 10px;
        font-family: "demonregular";
        font-style: normal;
        font-weight: 400;
        font-size: 30px;
        line-height: 100%;
        text-align: center;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        opacity: 0.6;
        color: white;
      `}
    >
      {text}
    </a>
  );
};


export default Header;
