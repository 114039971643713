const Soldout = () => {
  return (
    <div className="form">
      <a
        href="https://opensea.io/collection/babyghosts?search[sortAscending]=true&search[sortBy]=PRICE&search[toggles][0]=BUY_NOW"
        className="btn-primary"
        target="_blank"
        rel="noreferrer"
      >
        <span className="btn-inner">
          SOLD OUT - BUY ON OPENSEA <span className="icon-opensea1 icon"></span>
        </span>
      </a>
      <span className="form-txt">
        <span className="purple">10,000</span> / 10,000 MINTED
      </span>
    </div>
  );
};
export default Soldout;
