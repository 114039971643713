/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Col, Container, Row } from "react-bootstrap";
import { useAccount } from "wagmi";
import ConnectButton from "../components/ConnectButton";
import StakingDashboard from "../components/StakingDashboard";

function Staking() {
  const [{ data }] = useAccount({
    fetchEns: true,
  });

  return (
    <main id="main" css={css``}>
      <StakeHeader />

      <div
        className="viewport-section"
        css={css`
          max-width: 1350px;
          margin: auto;
        `}
      >
        {data ? <StakingDashboard address={data.address} /> : <ConnectButton />}
      </div>
    </main>
  );
}

export default Staking;

function SubTitle1() {
  return (
    <span
      css={css`
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 150%;
        color: #ffffff;
      `}
    >
      <span
        css={css`
          font-weight: 600;
        `}
      >
        Earn
        <span
          css={css`
            color: #8c4eff;
          `}
        >
          {" "}
          $BOO
        </span>{" "}
        tokens every day by staking your ghosts!
        <br />
      </span>
      The reward will depends of your ghosts rarity. The rarest it is, the more
      you earn tokens.
    </span>
  );
}

function SubTitle2() {
  return (
    <span
      css={css`
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 150%;
        color: #ffffff;
        opacity: 0.5;
      `}
    >
      Soft Staking is a gas-free way of earning rewards with your ghosts, simply
      by holding your NFTs in your wallet without selling or transferring them
      for the duration of the staking lock period. Find all details of our
      staking concept in our whitepaper.
    </span>
  );
}

function StakeHeader() {
  return (
    <div>
      <div
        className="banner viewport-section"
        css={css`
          padding-bottom: 10px;
        `}
      >
        <div className="container">
          <div className="bg-stretch bg-holloween">
            <span data-srcset="images/bg11.png, images/bg11@2x.png 2x"></span>
            <span
              data-srcset="images/bg11.png, images/bg11@2x.png 2x"
              data-media="(min-width: 768px)"
            ></span>
            <span
              data-srcset="images/bg11.png, images/bg11@2x.png 2x"
              data-media="(min-width: 1024px)"
            ></span>
          </div>
          <Container
            css={css`
              margin: 0 auto 0px;
              position: relative;
              z-index: 2;
              text-align: center;
              max-width: 1350px;
            `}
          >
            <Row
              css={css`
                justify-content: center;
              `}
            >
              <Col
                css={css`
                  max-width: 700px;
                  text-align: left;
                `}
              >
                <Row
                  css={css`
                    padding-bottom: 20px;
                  `}
                >
                  <h1>Soft Staking</h1>
                </Row>

                <Row
                  css={css`
                    padding-bottom: 20px;
                  `}
                >
                  <SubTitle1 />
                </Row>
                <Row>
                  <SubTitle2 />
                </Row>
              </Col>
              <Col className="d-none d-lg-block">
                <div className="meet-ghost">
                  <div className="ghost1">
                    <picture>
                      <source
                        srcSet="images/img6.png, images/img6@2x.png 2x"
                        media="(min-width: 768px)"
                      />
                      <source
                        srcSet="images/img6.png, images/img6@2x.png 2x"
                        media="(min-width: 1024px)"
                      />
                      <source srcSet="images/img6.png, images/img6@2x.png 2x" />
                      <img src="images/img6.png" alt="Ghost" />
                    </picture>
                  </div>
                  <div className="ghost2">
                    <picture>
                      <source
                        srcSet="images/img7.png, images/img7@2x.png 2x"
                        media="(min-width: 768px)"
                      />
                      <source
                        srcSet="images/img7.png, images/img7@2x.png 2x"
                        media="(min-width: 1024px)"
                      />
                      <source srcSet="images/img7.png, images/img7@2x.png 2x" />
                      <img src="images/img7.png" alt="Ghost" />
                    </picture>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </div>
  );
}
