import axios from "axios";
import { ethers } from "ethers";
import { useEffect, useState } from "react";
import contractABI from "./contract-abi.json";
const contractAddress = process.env.REACT_APP_CONTRACT_ADDRESS;

const useNftsMetadata = ({ tokenIds, address }) => {
  const [data, setData] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const babyGhostContract = new ethers.Contract(
      contractAddress,
      contractABI,
      signer
    );

    const loadNftsMetadata = async (tokenIds) => {
      setLoading(true);
      setError(false);
      try {
        const tokenUris = await Promise.all(
          tokenIds.map((tokenId) => babyGhostContract.tokenURI(tokenId))
        );

        const requests = await Promise.all(
          tokenUris.map((tokenUri) => axios(tokenUri))
        );

        const data = requests.map((request, index) => {
          return {
            tokenId: tokenIds[index],
            ...request.data,
          };
        });

        setData(data);
        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };
    loadNftsMetadata(tokenIds);
  }, [tokenIds, address]);

  return {
    data,
    loading,
    error,
  };
};

export default useNftsMetadata;
