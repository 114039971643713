import { useCollection } from "react-firebase-hooks/firestore";
import { db } from "./firestore";
import { collection } from "firebase/firestore";
import useNftsMetadata from "./useNftsMetadata";
import { format } from "date-fns";

const useNftsData = ({ tokenIds, address }) => {
  // Load metadata from contract
  const {
    data: dataMetadata,
    loading: loadingMetadata,
    error: errorMetadata,
  } = useNftsMetadata({ tokenIds, address });

  // Load staking data from firebase
  const [dataFirebase, loadingFirebase, errorFirebase] = useCollection(
    collection(db, "wallets", address.toLowerCase(), "tokens")
  );

  const loading = loadingMetadata || loadingFirebase;
  const error = errorMetadata || errorFirebase;

  if (loading || error) {
    return { loading, error };
  }

  // Extract easy to use rarity attribute from metadata
  dataMetadata.forEach((el, index, array) => {
    array[index].rarity = el.attributes.find(
      (e) => e.trait_type === "Rarity"
    ).value;
  });

  // Format firebase data to a dict indexed by tokenId
  const dictFirebase = Object.assign(
    {},
    ...dataFirebase.docs.map((doc) => ({
      [doc.id]: {
        ...doc.data(),
        isStaked: doc.data().isStaked,
        stakedAtString: !!doc.data().stakedAt
          ? format(doc.data().stakedAt.toDate(), "LLLL do yyyy")
          : "",
      },
    }))
  );

  // Format data to a dict indexed by tokenId
  const data = Object.assign(
    {},
    ...dataMetadata.map((tokenMetadata) => ({
      [tokenMetadata.tokenId]: {
        ...tokenMetadata,
        ...dictFirebase[tokenMetadata.tokenId],
      },
    }))
  );

  return {
    data,
    loading,
    error,
  };
};

export default useNftsData;
